body {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--surface-a);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
}

@keyframes blinker {
  0% {
    opacity: 0.4;
  }
  0% {
    opacity: 0.4;
  }
  10% {
    opacity: 0.8;
  }
  20% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4
  }
}
